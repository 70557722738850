import MDBox from "components/MDBox";
import { Outlet, useLocation, useOutletContext, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import TabsComponent from "components/Tabs/Tabs";
import TLinkApi from "services/tlink.api";
import SurveyTitleCard from "../../../../components/SurveyTitleCard";
import { Backdrop, CircularProgress } from "@mui/material";
import SecuirtyConfigIcon from "../../../../assets/images/secuirtyConfig.svg";
import NotesIcon from "../../../../assets/images/notesIcon.svg";
import LogsIcon from "../../../../assets/images/logsIcon.svg";
import ActivityLog from "../../../ActivityLog";
import { useNavigate } from "react-router-dom";

function AddSurvey() {
  const [quotaQualificationQuestions, setQuotaQualificationQuestions] = useState([]);
  const [langId, setLangId] = useState("");
  const location = useLocation();
  const [value, setValue] = useState(0);
  const [subValue, setSubValue] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const { survey, setSurvey, setIsEdit, isEdit, project, setProject } = useOutletContext();
  const [surveySummary, setSurveySummary] = useState();

  let { id } = useParams();
  let navigate = useNavigate();

  const steps = [
    {
      label: "Basic Details",
      isDisabled: false,
      route: window.location.pathname === `/surveys/new` ? `/surveys/new` : `/surveys/${id}`,
    },
    {
      label: "Security Config",
      isDisabled: window.location.pathname === `/surveys/new` ? true : false,
      route: `/surveys/${id}/securityConfig/edit`,
    },
    {
      label: "Qualification",
      isDisabled: window.location.pathname === `/surveys/new` ? true : false,
      route: `/surveys/${id}/qualification/edit`,
    },
    {
      label: "Quota",
      isDisabled: window.location.pathname === `/surveys/new` ? true : false,
      route: `/surveys/${id}/quota/edit`,
    },
    {
      label: "Suppliers",
      isDisabled: window.location.pathname === `/surveys/new` ? true : false,
      route: `/surveys/${id}/supplier/edit`,
    },
    {
      label: "Reports",
      isDisabled: window.location.pathname === `/surveys/new` ? true : false,
      route: `/surveys/${id}/report/edit`,
    },
    {
      label: "Respondent Status",
      isDisabled: window.location.pathname === `/surveys/new` ? true : false,
      route: `/surveys/${id}/respondent/edit`,
    },
  ];

  useEffect(() => {
    if (window.location.pathname === `/surveys/new`) {
      setIsEdit({
        disabled: false,
        edit: false,
      });
    }
    if (window.location.pathname === `/surveys/${id}`) {
      setValue(0);
      setIsEdit({
        disabled: true,
        edit: false,
      });
    } else if (window.location.pathname === `/surveys/${id}/edit`) {
      setValue(0);
    } else if (window.location.pathname === `/surveys/new`) {
      setValue(0);
    } else if (window.location.pathname === `/surveys/${id}/securityConfig/edit`) {
      setValue(1);
    } else if (window.location.pathname === `/surveys/${id}/qualification/edit`) {
      setValue(2);
    } else if (window.location.pathname === `/surveys/${id}/quota/edit`) {
      setValue(3);
    } else if (window.location.pathname === `/surveys/${id}/supplier/edit`) {
      setValue(4);
    } else if (window.location.pathname === `/surveys/${id}/report/edit`) {
      setValue(5);
    } else if (window.location.pathname === `/surveys/${id}/respondent/edit`) {
      setValue(6);
    } else if (window.location.pathname === `/surveys/${id}/notes/edit`) {
      setValue(-1);
      setSubValue(0);
    }
  }, [location]);

  let getSummaryData = async () => {
    if (id) {
      if (id !== "new") {
        setIsLoading(true);
        if (window.location.pathname === `/surveys/${id}/edit`) {
          setIsEdit({
            disabled: false,
            edit: true,
          });
        } else {
          setIsEdit({
            disabled: true,
            edit: false,
          });
        }
        try {
          let result = await TLinkApi.get(`/survey/summary/${id}`);
          setSurveySummary(result.data);
          setIsLoading(false);
        } catch (e) {
          console.log(e);
          setIsLoading(false);
        }
      }
    }
  };

  let getData = async () => {
    if (id) {
      if (id !== "new") {
        setIsLoading(true);
        if (window.location.pathname === `/surveys/${id}/edit`) {
          setIsEdit({
            disabled: false,
            edit: true,
          });
        } else {
          setIsEdit({
            disabled: true,
            edit: false,
          });
        }
        try {
          let result = await TLinkApi.get(`/survey/${id}`);
          setSurvey(result.data);
          setLangId(result.data.languageId);
          await getSummaryData();
          setIsLoading(false);
          if (result.status === 404) {
            navigate("/permissiondenied");
          }
        } catch (e) {
          console.log(e);
          setIsLoading(false);
        }
      }
    }
  };

  useEffect(() => {
    if (id) {
      getData();
    }
  }, [id]);

  return (
    <MDBox>
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <SurveyTitleCard
        newData={window.location.pathname === `/surveys/new` ? true : false}
        name={
          window.location.pathname === `/surveys/new` ? "New Survey" : surveySummary?.surveyName
        }
        survey={surveySummary}
        getData={getData}
        project={project}
      />

      <TabsComponent
        setValue={setValue}
        value={value}
        labels={steps}
        subValue={subValue}
        sublabels={[
          {
            label: `${NotesIcon}`,
            isDisabled: window.location.pathname === `/surveys/new` ? true : false,
            route: `/surveys/${id}/notes/edit`,
            name: "Notes",
          },
          {
            label: `${LogsIcon}`,
            isDisabled: window.location.pathname === `/surveys/new` ? true : false,
            name: "Activity logs",
            htmlContainer: <ActivityLog objectTypeId={2} />,
          },
        ]}
        tabpanels={
          <Outlet
            context={{
              quotaQualificationQuestions,
              setQuotaQualificationQuestions,
              survey,
              setSurvey,
              setIsEdit,
              isEdit,
              setLangId,
              langId,
              getData,
              project,
              setProject,
            }}
          />
        }
      />
    </MDBox>
  );
}

export default AddSurvey;
