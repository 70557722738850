import { Outlet } from "react-router-dom";
import React, { useState } from "react";

function Projects() {
  const [survey, setSurvey] = useState({});
  const [project, setProject] = useState({});
  const [isEdit, setIsEdit] = useState({
    disabled: false,
    edit: false,
  });
  const [isManual, setIsManual] = useState(false);
  return <Outlet context={{ setIsEdit, isEdit, survey, setSurvey, project, setProject, isManual, setIsManual }} />;
}

export default Projects;
