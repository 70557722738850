import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  Backdrop,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
  Stack,
  Checkbox,
  ListSubheader,
  TextField,
  Chip,
  FormControlLabel,
  Switch,
} from "@mui/material";
import useTableTheme from "constant/TableTheme";
import { ThemeProvider } from "@mui/material";
import { useOutletContext } from "react-router-dom";
import GetProjectsList from "hooks/ProjectList/GetProjectList";
import SearchInput from "components/searchInput";
import MDTypography from "components/MDTypography";
import colors from "assets/theme/base/colors";
import GetListOfClients from "hooks/ListOfClients/GetListOfClients";
import GetProjectManagerList from "hooks/ProjectManagerList/GetProjectManagerList";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import ClearIcon from "@mui/icons-material/Clear";
import InputAdornment from "@mui/material/InputAdornment";

function ProjectsList() {
  const navigate = useNavigate();
  const { setIsEdit, setIsManual } = useOutletContext();
  const { clients } = GetListOfClients();
  const { projectManager, setManagerSearch, managerSearch } = GetProjectManagerList();
  const [filtersLength, setFiltersLength] = useState({
    clientLength: 0,
    projectLength: 0,
    statusLength: 0,
  });
  const {
    projects,
    getProjects,
    isLoading,
    totalLength,
    setPage,
    setSearch,
    search,
    setEntriesPerPage,
    setProjecStatusFilter,
    projectStatusFilter,
    setSortBy,
    setSortOrder,
    clientFilter,
    setClientFilter,
    projectManagerFilter,
    setProjectManagerFilter,
    setOnload,
    manual,
    setManual,
  } = GetProjectsList(filtersLength);

  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 50,
  });
  const [sorting, setSorting] = useState([]);
  const tableTheme = useTableTheme();
  const [searchClient, setSearchClient] = useState("");
  const [searchClientList, setSearchClientList] = useState([]);
  const [statusSearch, setStatusSearch] = useState("");
  const [searchStatusList, setSearchStatusList] = useState([]);
  const [statusList, setStatusList] = useState([]);

  let { textColor } = colors;

  useEffect(() => {
    setIsManual(manual);
  }, [manual]);

  useEffect(() => {
    let payload = { ...filtersLength };
    payload.clientLength = clients.length;
    payload.projectLength = projectManager.length;
    setFiltersLength(payload);
  }, [projectManager, clients]);

  useEffect(() => {
    setData(projects);
    setPage(pagination.pageIndex);
    setEntriesPerPage(pagination.pageSize);
    if (sorting.length === 1) {
      if (sorting[0].desc === false) {
        setSortBy(sorting[0].id);
        setSortOrder("asc");
      } else {
        setSortBy(sorting[0].id);
        setSortOrder("desc");
      }
    } else if (sorting.length === 0) {
      setSortBy("id");
      setSortOrder("asc");
    }
  }, [projects, pagination, sorting]);

  useEffect(() => {
    setSearchClientList(clients);
  }, [clients]);

  useEffect(() => {
    let searchData = clients?.filter((data) => {
      return data?.name.toLowerCase().includes(searchClient?.toLowerCase());
    });
    setSearchClientList(searchData);
  }, [searchClient]);

  useEffect(() => {
    let searchStatusData = statusList.filter((data) => {
      return data.name.toLowerCase().includes(statusSearch.toLowerCase());
    });
    setSearchStatusList(searchStatusData);
  }, [statusSearch, statusList]);

  useEffect(() => {
    let objectList = [
      { id: 1, name: "Awarded" },
      { id: 2, name: "Live" },
      { id: 3, name: "Pause/On hold" },
      { id: 4, name: "Completed" },
      { id: 5, name: "Reconciled" },
      { id: 6, name: "Ready to invoice" },
      { id: 7, name: "Closed" },
      { id: 8, name: "Permanent Closed" },
      { id: 9, name: "System Closed" },
    ];
    setStatusList(objectList);
    let payload = { ...filtersLength };
    payload.statusLength = objectList.length;
    setFiltersLength(payload);
  }, [projects]);

  let columns = [
    {
      accessorKey: "id",
      header: "Project Id",
      size: 50,
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
      Cell: ({ cell }) => (
        <div
          style={{ cursor: "pointer" }}
          onClick={() => {
            setIsEdit({
              disabled: true,
              edit: false,
            });
            navigate(`/projects/${cell.getValue()}/performance`);
          }}
        >
          {cell.getValue()}
        </div>
      ),
    },
    {
      accessorKey: "clientName",
      header: "Client Name",
      size: 50,
      enableSorting: false,
      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        align: "left",
      },
      headerAlign: "center",
      Cell: ({ cell }) => {
        const clientName = cell.getValue();
        const truncatedName =
          clientName?.length > 15 ? clientName.substring(0, 15) + "..." : clientName;
        return (
          <Tooltip title={cell.getValue()} placement="bottom-start">
            <div>{truncatedName}</div>
          </Tooltip>
        );
      },
    },
    {
      accessorKey: "name",
      header: "Project Name",
      size: 50,
      enableSorting: false,
      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        align: "left",
      },
      headerAlign: "center",
      Cell: ({ cell }) => {
        const name = cell.getValue();
        const truncatedName = name?.length > 15 ? name.substring(0, 15) + "..." : name;
        return (
          <Tooltip title={name} placement="bottom-start">
            <div>{truncatedName}</div>
          </Tooltip>
        );
      },
    },
    {
      accessorKey: "startDate",
      header: "Start Date",
      size: 50,
      headerAlign: "center",
      Cell: ({ cell }) => {
        return (
          <Tooltip title={moment(cell.getValue()).format("YYYY-MM-DD, h:mm:ss a")}>
            <div>{moment(cell.getValue()).format("YYYY-MM-DD")}</div>
          </Tooltip>
        );
      },
    },

    {
      accessorKey: "endDate",
      header: "End Date",
      size: 50,
      headerAlign: "center",
      Cell: ({ cell }) => {
        return (
          <Tooltip title={moment(cell.getValue()).format("YYYY-MM-DD, h:mm:ss a")}>
            <div>{moment(cell.getValue()).format("YYYY-MM-DD")}</div>
          </Tooltip>
        );
      },
    },

    {
      accessorKey: "projectStatusName",
      header: "Status",
      align: "center",
      size: 50,
      enableSorting: false,
      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        align: "left",
      },
      headerAlign: "center",
      Cell: ({ cell }) => {
        return cell.getValue() === 1
          ? "Awarded"
          : cell.getValue() === 2
          ? "Live"
          : cell.getValue() === 3
          ? "Pause/On hold"
          : cell.getValue() === 4
          ? "Completed"
          : cell.getValue() === 5
          ? "Reconciled"
          : cell.getValue() === 6
          ? "Ready to invoice"
          : cell.getValue() === 7
          ? "Closed"
          : cell.getValue() === 8
          ? "Permanent Closed"
          : cell.getValue() === 9
          ? "System Closed"
          : cell.getValue();
      },
    },
  ];

  let selectStyle = {
    height: "35px",
    fontSize: "15px",
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #D9D9D9",
      borderRadius: "0px",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #D9D9D9",
      borderRadius: "0px",
    },
    "& :hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #D9D9D9",
      borderRadius: "0px",
    },
  };

  const table = useMaterialReactTable({
    columns,
    data,
    enableStickyHeader: true,
    manualPagination: true,
    rowCount: totalLength,
    onPaginationChange: setPagination,
    state: { density: "compact", pagination, sorting },
    enableColumnPinning: true,
    enableGlobalFilter: false,
    enableDensityToggle: false,
    manualSorting: true,
    muiPaginationProps: {
      rowsPerPageOptions: [25, 50, 100],
    },
    muiTableBodyRowProps: ({ row }) => ({
      onClick: (event) => {
        navigate(`/projects/${row.original.id}/performance`);
      },
      sx: {
        cursor: "pointer",
      },
    }),
    onSortingChange: setSorting,
    muiTableContainerProps: {
      sx: {
        maxHeight: "calc(100vh - 380px)",
        overflowY: "auto",
      },
    },
    muiTablePaperProps: {
      elevation: 0,
      sx: {
        width: "100%",
        borderRadius: "0",
        backgroundColor: "#fff !important",
        zIndex: 500,
      },
    },
    renderEmptyRowsFallback: () => (
      <Stack display="flex" alignItems="center" justifyContent="center" fontSize="14px" p={5}>
        No records to display
      </Stack>
    ),
  });

  return (
    <>
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <MDBox pb={0} width="100%">
        <Grid container spacing={2} pb={1} display="flex" flexDirection="row">
          <Grid
            px={2}
            item
            xs={6}
            md={6}
            sx={{
              flexDirection: { xs: "column", md: "row" },
              alignItems: { xs: "flex-end", md: "center" },
            }}
            gap="15px"
          >
            <MDTypography fontSize="25px" color="black" ml="1%">
              Projects
            </MDTypography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} md={12}>
            <hr style={{ border: `1px solid ${textColor.main}`, width: "100%" }} />
          </Grid>
        </Grid>
        <Grid container sx={{ marginTop: "10px" }}>
          <Grid
            px={0}
            item
            xs={6}
            md={6}
            sx={{
              flexDirection: { xs: "column", md: "row" },
              alignItems: { xs: "flex-end", md: "center" },
            }}
            gap="15px"
          >
            <MDTypography color="black" ml="1%">
              Project List
            </MDTypography>
          </Grid>
          <Grid
            px={2}
            item
            xs={6}
            md={6}
            display="flex"
            justifyContent="flex-end"
            flexDirection="row"
            sx={{
              flexDirection: { xs: "column", md: "row" },
              alignItems: { xs: "flex-end", md: "center" },
            }}
            gap="15px"
          >
            <FormControlLabel
              control={
                <Switch
                  size="small"
                  checked={manual}
                  onChange={(e) => {
                    setOnload(false);
                    setManual(e.target.checked);
                  }}
                />
              }
              label={"Manual"}
            />
            <Tooltip title="Create New Project" placement="top">
              <MDButton
                variant="contained"
                color="info"
                size="small"
                onClick={() => {
                  navigate("/projects/new");
                  setIsEdit({
                    disabled: false,
                    edit: false,
                  });
                }}
              >
                + Project
              </MDButton>
            </Tooltip>
          </Grid>
        </Grid>
        <MDBox display="flex" justifyContent="center">
          <MDBox py={2} borderRadius="5px" bgColor="white" width="100%">
            <Grid container spacing={2}>
              <Grid item xs={10} display="flex" justifyContent="flex-start" flexDirection="row">
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <FormControl
                      fullWidth
                      size="small"
                      sx={{
                        label: {
                          fontWeight: "400",
                        },
                      }}
                    >
                      <InputLabel>Clients</InputLabel>
                      <Select
                        multiple
                        value={Array.isArray(clientFilter) ? clientFilter : []}
                        label="Clients"
                        MenuProps={{
                          autoFocus: false,
                        }}
                        sx={selectStyle}
                        renderValue={(selected) => (
                          <div>
                            {selected.slice(0, 1).map((option, index) => (
                              <span>
                                {
                                  searchClientList.find(
                                    (item) => Number(item.id) === Number(option)
                                  )?.name
                                }
                              </span>
                            ))}
                            {selected?.length > 1 && (
                              <Chip
                                sx={{ backgroundColor: "white" }}
                                label={`+${selected?.length - 1} more`}
                                style={{
                                  height: "20px",
                                  marginTop: "3px",
                                }}
                              />
                            )}
                          </div>
                        )}
                      >
                        <ListSubheader sx={{ marginBottom: "1rem" }}>
                          <TextField
                            sx={{
                              position: "sticky",
                              marginTop: "-16px",
                              paddingTop: "1rem",
                              zIndex: 1,
                              background: "white",
                            }}
                            size="small"
                            autoFocus
                            value={searchClient}
                            placeholder="Type to search..."
                            fullWidth
                            onChange={(e) => setSearchClient(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key !== "Escape") {
                                e.stopPropagation();
                              }
                            }}
                            InputProps={{
                              endAdornment: searchClient && (
                                <InputAdornment
                                  position="end"
                                  onClick={() => setSearchClient("")}
                                  style={{ cursor: "pointer" }}
                                >
                                  <ClearIcon sx={{ fontSize: "small" }} />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </ListSubheader>
                        <MenuItem
                          onClick={() => {
                            setOnload(false);
                            if (
                              Array.isArray(clientFilter) &&
                              clientFilter.length === searchClientList?.length
                            ) {
                              setTimeout(() => {
                                setClientFilter([]);
                              }, 200);
                            } else {
                              setTimeout(() => {
                                setClientFilter(searchClientList?.map((value) => value.id));
                              }, 200);
                            }
                          }}
                        >
                          <Checkbox
                            checked={
                              Array.isArray(clientFilter) &&
                              clientFilter.length === searchClientList?.length &&
                              searchClientList?.length > 0
                            }
                          />
                          Select All
                        </MenuItem>

                        {Array.isArray(searchClientList) &&
                          searchClientList.map((value, i) => (
                            <MenuItem
                              value={value.id}
                              key={i}
                              onClick={() => {
                                if (clientFilter.includes(value.id)) {
                                  let payload = clientFilter?.filter((val) => val != value.id);
                                  setOnload(false);
                                  setTimeout(() => {
                                    setClientFilter(payload);
                                  }, 200);
                                } else {
                                  let payload = [...clientFilter];
                                  payload.push(value.id);
                                  setOnload(false);
                                  setTimeout(() => {
                                    setClientFilter(payload);
                                  }, 200);
                                }
                              }}
                            >
                              <Checkbox
                                checked={
                                  Array.isArray(clientFilter) && clientFilter.includes(value.id)
                                }
                              />
                              {value.name}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={3}>
                    <FormControl
                      fullWidth
                      size="small"
                      sx={{
                        label: {
                          fontWeight: "400",
                        },
                      }}
                    >
                      <InputLabel>Project Manager</InputLabel>
                      <Select
                        multiple
                        value={Array.isArray(projectManagerFilter) ? projectManagerFilter : []}
                        sx={selectStyle}
                        label="Project Manager"
                        MenuProps={{
                          autoFocus: false,
                        }}
                        renderValue={(selected) => (
                          <div>
                            {selected.slice(0, 1).map((option, index) => (
                              <span>
                                {
                                  projectManager.find((item) => Number(item.id) === Number(option))
                                    ?.fullName
                                }
                              </span>
                            ))}
                            {selected?.length > 1 && (
                              <Chip
                                sx={{ backgroundColor: "white" }}
                                label={`+${selected?.length - 1} more`}
                                style={{
                                  height: "20px",
                                  marginTop: "3px",
                                }}
                              />
                            )}
                          </div>
                        )}
                      >
                        <ListSubheader sx={{ marginBottom: "1rem" }}>
                          <TextField
                            sx={{
                              position: "sticky",
                              marginTop: "-16px",
                              paddingTop: "1rem",
                              zIndex: 1,
                              background: "white",
                            }}
                            size="small"
                            autoFocus
                            required
                            value={managerSearch}
                            placeholder="Type to search..."
                            fullWidth
                            onChange={(e) => setManagerSearch(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key !== "Escape") {
                                e.stopPropagation();
                              }
                            }}
                            InputProps={{
                              endAdornment: managerSearch && (
                                <InputAdornment
                                  position="end"
                                  onClick={() => setManagerSearch("")}
                                  style={{ cursor: "pointer" }}
                                >
                                  <ClearIcon sx={{ fontSize: "small" }} />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </ListSubheader>
                        <MenuItem
                          onClick={() => {
                            if (
                              Array.isArray(projectManagerFilter) &&
                              projectManagerFilter.length === projectManager.length
                            ) {
                              setOnload(false);
                              setTimeout(() => {
                                setProjectManagerFilter([]);
                              }, 200);
                            } else {
                              setOnload(false);
                              setTimeout(() => {
                                setProjectManagerFilter(
                                  projectManager?.map((value) => String(value.id))
                                );
                              }, 200);
                            }
                          }}
                        >
                          <Checkbox
                            checked={
                              Array.isArray(projectManagerFilter) &&
                              projectManagerFilter.length === projectManager.length
                            }
                          />
                          Select All
                        </MenuItem>
                        {projectManager?.map((value, index) => (
                          <MenuItem
                            key={index}
                            value={String(value.id)}
                            onClick={() => {
                              if (projectManagerFilter.includes(String(value.id))) {
                                let payload = projectManagerFilter?.filter(
                                  (val) => String(val) !== String(value.id)
                                );
                                setOnload(false);
                                setTimeout(() => {
                                  setProjectManagerFilter(payload);
                                }, 200);
                              } else {
                                let payload = [...projectManagerFilter];
                                payload.push(String(value.id));
                                setOnload(false);
                                setTimeout(() => {
                                  setProjectManagerFilter(payload);
                                }, 200);
                              }
                            }}
                          >
                            <Checkbox
                              checked={
                                Array.isArray(projectManagerFilter) &&
                                projectManagerFilter.includes(String(value.id))
                              }
                            />
                            {value.fullName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={3}>
                    <FormControl fullWidth size="small">
                      <InputLabel>Status</InputLabel>
                      <Select
                        multiple
                        value={Array.isArray(projectStatusFilter) ? projectStatusFilter : []}
                        label="Status"
                        MenuProps={{
                          autoFocus: false,
                        }}
                        sx={selectStyle}
                        renderValue={(selected) => (
                          <div>
                            {selected.slice(0, 1).map((option, index) => (
                              <span>
                                {
                                  searchStatusList.find(
                                    (item) => Number(item.id) === Number(option)
                                  )?.name
                                }
                              </span>
                            ))}
                            {selected?.length > 1 && (
                              <Chip
                                sx={{ backgroundColor: "white" }}
                                label={`+${selected?.length - 1} more`}
                                style={{
                                  height: "20px",
                                  marginTop: "3px",
                                }}
                              />
                            )}
                          </div>
                        )}
                      >
                        <ListSubheader sx={{ marginBottom: "1rem" }}>
                          <TextField
                            sx={{
                              position: "sticky",
                              marginTop: "-16px",
                              paddingTop: "1rem",
                              zIndex: 1,
                              background: "white",
                            }}
                            size="small"
                            autoFocus
                            required
                            value={statusSearch}
                            placeholder="Type to search..."
                            fullWidth
                            onChange={(e) => setStatusSearch(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key !== "Escape") {
                                e.stopPropagation();
                              }
                            }}
                            InputProps={{
                              endAdornment: statusSearch && (
                                <InputAdornment
                                  position="end"
                                  onClick={() => setStatusSearch("")}
                                  style={{ cursor: "pointer" }}
                                >
                                  <ClearIcon sx={{ fontSize: "small" }} />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </ListSubheader>
                        <MenuItem
                          onClick={() => {
                            if (
                              Array.isArray(projectStatusFilter) &&
                              projectStatusFilter.length === searchStatusList.length
                            ) {
                              setOnload(false);
                              setTimeout(() => {
                                setProjecStatusFilter([]);
                              }, 200);
                            } else {
                              setOnload(false);
                              setTimeout(() => {
                                setProjecStatusFilter(
                                  searchStatusList?.map((value) => String(value.id))
                                );
                              }, 200);
                            }
                          }}
                        >
                          <Checkbox
                            checked={
                              Array.isArray(projectStatusFilter) &&
                              projectStatusFilter.length === statusList.length &&
                              statusList.length > 0
                            }
                          />
                          Select All
                        </MenuItem>
                        {searchStatusList?.map((value, i) => (
                          <MenuItem
                            key={i}
                            value={value.id}
                            onClick={() => {
                              if (projectStatusFilter.includes(String(value.id))) {
                                let payload = projectStatusFilter?.filter(
                                  (val) => String(val) !== String(value.id)
                                );
                                setOnload(false);
                                setTimeout(() => {
                                  setProjecStatusFilter(payload);
                                }, 200);
                              } else {
                                let payload = [...projectStatusFilter];
                                payload.push(String(value.id));
                                setOnload(false);
                                setTimeout(() => {
                                  setProjecStatusFilter(payload);
                                }, 200);
                              }
                            }}
                          >
                            <Checkbox
                              checked={
                                Array.isArray(projectStatusFilter) &&
                                projectStatusFilter.includes(String(value.id))
                              }
                            />
                            {value.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={3}>
                    <MDButton
                      variant="contained"
                      color="info"
                      size="small"
                      onClick={() => {
                        getProjects();
                      }}
                    >
                      Apply Filter
                    </MDButton>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={2} display="flex" justifyContent="flex-end" flexDirection="row">
                <SearchInput setSearch={setSearch} search={search} />
              </Grid>
            </Grid>
            <ThemeProvider theme={tableTheme}>
              <MaterialReactTable
                table={table}
                style={{
                  width: "100%",
                }}
              />
            </ThemeProvider>
          </MDBox>
        </MDBox>
      </MDBox>
    </>
  );
}

export default ProjectsList;
