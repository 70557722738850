import React, { useState, useEffect } from "react";
import MDBox from "components/MDBox";
import { Outlet, useLocation, useOutletContext, useParams } from "react-router-dom";
import { Grid } from "@mui/material";
import TabsComponent from "components/Tabs/Tabs";
import TLinkApi from "services/tlink.api";
import { Backdrop, CircularProgress } from "@mui/material";
import NotesIcon from "../../../../assets/images/notesIcon.svg";
import ProjectTitleCard from "../../../../components/ProjectTitleCard";
import LogsIcon from "../../../../assets/images/logsIcon.svg";
import ActivityLog from "../../../ActivityLog";
import { useNavigate } from "react-router-dom";

function AddProject() {
  const [quotaQualificationQuestions, setQuotaQualificationQuestions] = useState([]);
  const [langId, setLangId] = useState("");
  const [step, setStep] = useState([]);
  const [stepPos, setStepPos] = useState(0);
  const location = useLocation();
  const [projectSummary, setProjectSummary] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [subValue, setSubValue] = useState(0);
  const [status, setStatus] = useState([]);
  const [surveyStatusId, setSurveyStatusId] = useState(null);

  const { survey, setSurvey, setIsEdit, isEdit, project, setProject, isManual, setIsManual } = useOutletContext();

  let { id, reconcileId } = useParams();
  let navigate = useNavigate();

  useEffect(() => {
    if (window.location.pathname === `/projects/${id}/reconciliation`) {
      setStepPos(4);
    } else if (window.location.pathname === `/projects/${id}/reconciliation/${reconcileId}`) {
      setStepPos(4);
    } else if (window.location.pathname === `/projects/${id}/reconciliation/new`) {
      setStepPos(4);
    } else if (window.location.pathname === `/projects/${id}/surveyList`) {
      setStepPos(2);
    } else if (window.location.pathname === `/projects/${id}/edit`) {
      setStepPos(1);
    } else if (window.location.pathname === `/projects/${id}`) {
      setStepPos(1);
    } else if (window.location.pathname === `/projects/new`) {
      setStepPos(1);
    } else if (window.location.pathname === `/projects/${id}/performance`) {
      setStepPos(0);
    } else if (window.location.pathname === `/projects/${id}/team`) {
      setStepPos(3);
    } else if (window.location.pathname === `/projects/${id}/notes/edit`) {
      setStepPos(-1);
      setSubValue(0);
    }
  }, [location]);

  const GetStatus = async () => {
    setIsLoading(true);
    try {
      let result = await TLinkApi.get(`/masters/projectstatus`);
      setStatus(result.data);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  let getProjectSummary = async () => {
    if (id) {
      if (id !== "new") {
        setIsLoading(true);
        if (window.location.pathname === `/projects/${id}/edit`) {
          setIsEdit({
            disabled: false,
            edit: true,
          });
        } else {
          setIsEdit({
            disabled: true,
            edit: false,
          });
        }
        try {
          let result = await TLinkApi.get(`/projects/summary/${id}`);
          setProjectSummary(result.data);
          setIsLoading(false);
        } catch (e) {
          console.log(e);
          setIsLoading(false);
        }
      }
    }
  };

  useEffect(() => {
    setSurveyStatusId(projectSummary?.status);
  }, [projectSummary]);

  const getData = async () => {
    if (id) {
      if (id !== "new") {
        setIsLoading(true);
        if (window.location.pathname === `/projects/${id}/edit`) {
          setIsEdit({
            disabled: false,
            edit: true,
          });
        } else {
          setIsEdit({
            disabled: true,
            edit: false,
          });
        }
        try {
          let result = await TLinkApi.get(`/projects/${id}`);
          setProject(result.data);
          if (result.status === 404) {
            navigate("/permissiondenied");
          }        
        } catch (e) {
          console.log(e);
          setIsLoading(false);
        }
      }
    }
  };

  useEffect(() => {
    if (Object.keys(project).length > 0) {
      getProjectSummary();
      GetStatus();
    }
  }, [project]);

  useEffect(() => {
    if (id) {
      getData();
    }
  }, [id]);

  const steps = [
    {
      label: "Performance",
      isDisabled: window.location.pathname === `/projects/new` ? true : false,
      route: `/projects/${id}/performance`,
    },
    {
      label: "Basic Details",
      isDisabled: false,
      route: window.location.pathname === `/projects/new` ? `/projects/new` : `/projects/${id}`,
    },
    {
      label: "Survey List",
      isDisabled: window.location.pathname === `/projects/new` ? true : false,
      route: `/projects/${id}/surveyList`,
    },
    {
      label: "Team",
      isDisabled: window.location.pathname === `/projects/new` ? true : false,
      route: `/projects/${id}/team`,
    },
    {
      label: "Reconciliation",
      isDisabled: window.location.pathname === `/projects/new` ? true : false,
      route: `/projects/${id}/reconciliation`,
    },
  ];

  return (
    <MDBox>
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <ProjectTitleCard
        newData={window.location.pathname === `/projects/new` ? true : false}
        name={window.location.pathname === `/projects/new` ? "New Project" : projectSummary?.name}
        project={projectSummary}
        status={status}
        getProjectSummary={getProjectSummary}
        getData={getData}
        setProject={setProject}
      />
      <Grid container spacing={0}>
        <TabsComponent
          setValue={setStepPos}
          value={stepPos}
          labels={steps}
          subValue={subValue}
          sublabels={[
            {
              label: `${NotesIcon}`,
              isDisabled: window.location.pathname === `/projects/new` ? true : false,
              route: `/projects/${id}/notes/edit`,
              name: "Notes",
            },
            {
              label: `${LogsIcon}`,
              isDisabled: window.location.pathname === `/projects/new` ? true : false,
              name: "Activity logs",
              htmlContainer: <ActivityLog objectTypeId={1} />,
            },
          ]}
          tabpanels={
            <Outlet
              context={{
                stepPos,
                setStepPos,
                subValue,
                setSubValue,
                step,
                setStep,
                quotaQualificationQuestions,
                setQuotaQualificationQuestions,
                survey,
                setSurvey,
                setIsEdit,
                isEdit,
                setLangId,
                langId,
                project,
                setProject,
                getData,
                surveyStatusId,
                isManual,
                setIsManual,
              }}
            />
          }
        />
      </Grid>
    </MDBox>
  );
}

export default AddProject;
